import React, { useState, useEffect } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pages/main/Main.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Pages/Login/Login';
import DashBoard from './Pages/dashboard/DashBoard';
import Dailygen from './Pages/dailygeneration/Dailygen';
import DGR from './Pages/dgr/DGR';
import CustomerTurbine from "./Pages/turbine/CustomerTurbine";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("session");
    setLoggedIn(!!session); // Sets loggedIn to true if session exists
  }, []);

  const handleLogin = () => {
    localStorage.setItem("session", "true");
    setLoggedIn(true);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Public route for Login */}
          <Route path="/login" element={loggedIn ? <Navigate to="/console/dashboard" replace /> : <Login onLogin={handleLogin} />} />
          
          {/* Protected routes */}
          <Route path="/console/dashboard" element={loggedIn ? <DashBoard /> : <Navigate to="/login" replace />} />
          <Route path="/console/dailygen" element={loggedIn ? <Dailygen /> : <Navigate to="/login" replace />} />
          <Route path="/console/dgr" element={loggedIn ? <DGR /> : <Navigate to="/login" replace />} />
          <Route path="/console/turbine" element={loggedIn ? <CustomerTurbine /> : <Navigate to="/login" replace />} />

          {/* Default route */}
          <Route path="/" element={<Navigate to={loggedIn ? "/console/dashboard" : "/login"} replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
