
// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row, Table, Spinner } from 'react-bootstrap';
// import 'react-datepicker/dist/react-datepicker.css';
// import Header from '../../Components/Header';
// import { Navigate } from 'react-router-dom';
// import moment from 'moment';
// import '../turbine/customerturbine.css'

// const CutomerTurbine = () => {
//     const [loading, setLoading] = useState(true);
//     const [turbine, setTurbine] = useState([]);
//     console.log("turbine", turbine)
//     const fetchData = async () => {
//         try {
//             const customerId = localStorage.getItem("customerId");
//             const response = await fetch("https://api.srivarugreenenergy.com/customerapi/customerturbine/list.php",
//                 {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify({
//                         user_id: customerId
//                     }),
//                 }
//             );

//             if (!response.ok) {
//                 throw new Error("Failed to fetch data");
//             }
//             const responseData = await response.json();
//             setLoading(false);
//             if (responseData.status === 200) {
//                 setTurbine(responseData.data);
//             } else {
//                 throw new Error(responseData.msg);
//             }
//         } catch (error) {
//             setLoading(false);
//             console.error("Error fetching data:", error.message);
//         }
//     };
//     useEffect(() => {
//         fetchData(); // Call fetchData directly in useEffect
//     }, []);



//     const [isLoggedIn, setIsLoggedIn] = useState(true);
//     useEffect(() => {
//         const checkSession = async () => {
//             try {
//                 // Check session logic here, e.g., fetch from local storage or server
//                 const session = localStorage.getItem('session');
//                 if (!session) {
//                     setIsLoggedIn(false);
//                 }
//             } catch (error) {
//                 console.error('Error checking session:', error.message);
//                 setIsLoggedIn(false);
//             }
//         };
//         checkSession();
//     }, []);
//     // Disable right-click globally
//     //  useEffect(() => {
//     //     const handleRightClick = (e) => {
//     //         e.preventDefault();
//     //     };

//     //     document.addEventListener('contextmenu', handleRightClick);

//     //     return () => {
//     //         document.removeEventListener('contextmenu', handleRightClick);
//     //     };
//     // }, []);
//     if (!isLoggedIn) {

//         return <Navigate to="/login" replace />;
//     }
//     const parsePdfFiles = (pdfFilesString) => {
//         try {
//             const pdfFiles = JSON.parse(pdfFilesString);
//             return pdfFiles.map(pdf => {
//                 // Ensure it ends with .pdf
//                 // const fileName = pdf.split('.pdf')[0] + '.pdf';
//                 const fileName = pdf.split('/').pop().replace(/\s?\d{4}-\d{2}-\d{2}.*$/, '')
//                 console.log("fileName", fileName)
//                 return `https://api.srivarugreenenergy.com/uploads/${fileName}`;
//             });
//         } catch (error) {
//             console.error("Error parsing PDF files:", error.message);
//             return []; // Return empty array if parsing fails
//         }
//     };

//     return (
//         <>


//             <div id="main" className='noselect' >
//                 <Header />
//                 <div className="bg-white my-3 rounded">
//                     {/* <Container fluid>
//                         <Row>
//                             <Col>
//                                 <h4>Turbine PDF Files</h4>
//                                 {loading ? (
//                                     <Spinner animation="border" />
//                                 ) : (
//                                     <Row className="g-3">

//                                         {turbine.map((item, index) => {
//                                             const pdfFiles = item.pdf_files ? parsePdfFiles(item.pdf_files) : [];
//                                             if (pdfFiles.length > 0) { 
//                                                 return (
//                                                     <Col md={4} key={index}>
//                                                         <div className="turbine-card">
//                                                             <h5>{item.wtg_no}</h5>
//                                                             <div className="pdf-files">
//                                                                 {pdfFiles.map((pdf, idx) => {
//                                                                     const fileName = pdf.split('/').pop();
//                                                                     return (
//                                                                         <a
//                                                                             key={idx}
//                                                                             href={pdf}
//                                                                             download
//                                                                             target="_blank"
//                                                                             rel="noopener noreferrer"
//                                                                             className="btn btn-primary btn-sm m-1"
//                                                                         >
//                                                                             {fileName} - Download
//                                                                         </a>
//                                                                     );
//                                                                 })}
//                                                             </div>
//                                                         </div>
//                                                     </Col>
//                                                 );
//                                             }
//                                             return null; // Return null if there are no PDF files
//                                         })}
//                                     </Row>
//                                 )}
//                             </Col>
//                         </Row>
//                     </Container> */}
//                     <div className='m-3'>
//                         <Row>
//                             <Col lg={12} md={12} xs={12} className='mb-5'>
//                                 <div className='h5 text-center mb-2'></div>
//                                 <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
//                                     <Table striped bordered hover className='noselect' >
//                                         <thead>
//                                             <tr>
//                                                 <th >S.No</th>
//                                                 <th >WEG No.</th>
//                                                 <th >Customer Name</th>
//                                                 <th >Loc. No</th>
//                                                 <th >HTSC NO</th>
//                                                 <th >DOC</th>
//                                                 <th >Site</th>
//                                                 <th >Place</th>
//                                                 <th >Model</th>
//                                                 <th >Capacity</th>
//                                                 <th >Tower Ht</th>
//                                                 <th >Contract type</th>
//                                                 <th >Feeder Voltage</th>
//                                                 <th >Feeder Name</th>
//                                                 <th >Substation</th>
//                                                 <th>PDF Files</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {loading ? (
//                                                 <tr>
//                                                     <td colSpan="15" className="text-center">Loading...</td>
//                                                 </tr>
//                                             ) : (
//                                                 turbine.map((item, index) => {
//                                                     const pdfFiles = item.pdf_files ? parsePdfFiles(item.pdf_files) : [];

//                                                 return (
//                                                     <tr key={index}>
//                                                         <td>{index + 1}</td>
//                                                         <td>{item.wtg_no}</td>
//                                                         <td>{item.customer_name}</td>
//                                                         <td>{item.loc_no}</td>
//                                                         <td>{item.htsc_no}</td>
//                                                         <td>{moment(item.date).format("DD-MM-YYYY")}</td>
//                                                         <td>{item.site_name}</td>
//                                                         <td>{item.location_name}</td>
//                                                         <td>{item.model_type}</td>
//                                                         <td>{item.capacity}</td>
//                                                         <td>{item.tower_ht}</td>
//                                                         <td>{item.contract_code}</td>
//                                                         <td>{item.feeder_voltage}</td>
//                                                         <td>{item.feed_name}</td>
//                                                         <td>{item.sub_station}</td>
//                                                         <td>
//                                                             {pdfFiles.length > 0 ? (
//                                                                 pdfFiles.map((pdf, idx) => {
//                                                                     const fileName = pdf.split('/').pop();
//                                                                     return (
//                                                                         <a
//                                                                             key={idx}
//                                                                             href={pdf}
//                                                                             download
//                                                                             target="_blank"
//                                                                             rel="noopener noreferrer"
//                                                                             className="btn btn-link btn-sm"
//                                                                         >
//                                                                             {fileName}
//                                                                         </a>
//                                                                     );
//                                                                 })
//                                                             ) : (
//                                                                 "No Files"
//                                                             )}
//                                                         </td>
//                                                     </tr>
//                                                 );
//                                             })
//                                             )}
//                                         </tbody>
//                                     </Table>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//             </div>
//         </>

//     );
// };

// export default CutomerTurbine;


import React, { useState, useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import Header from '../../Components/Header';

const CutomerTurbine = () => {
    const [loading, setLoading] = useState(true);
    const [turbine, setTurbine] = useState([]);
    console.log('turbine', turbine)
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customerId = localStorage.getItem("customerId");
                const response = await fetch("https://api.srivarugreenenergy.com/customerapi/customerturbine/list.php", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ user_id: customerId }),
                });

                if (!response.ok) throw new Error("Failed to fetch data");

                const responseData = await response.json();
                setLoading(false);
                if (responseData.status === 200) {
                    setTurbine(responseData.data);
                } else {
                    throw new Error(responseData.msg);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error.message);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const session = localStorage.getItem('session');
        setIsLoggedIn(!!session);
    }, []);

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />;
    }
    const getPdfFileUrl = (fileName, pdfId) => {
        return `https://api.srivarugreenenergy.com/uploads/${pdfId}_${fileName}`;
    };
    return (
        <>
            <div id="main" className="noselect">
                <Header />
                <div className="bg-white my-3 rounded">

                    <Row>
                        <Col lg={12} md={12} xs={12} className="mb-5">
                            <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }} className='m-2'>
                                <Table striped bordered hover className="noselect">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>WEG No.</th>
                                            <th>Customer Name</th>
                                            <th>Loc. No</th>
                                            <th>HTSC NO</th>
                                            <th>DOC</th>
                                            <th>Site</th>
                                            <th>Place</th>
                                            <th>Model</th>
                                            <th>Capacity</th>
                                            <th>Tower Ht</th>
                                            <th>Contract Type</th>
                                            <th>Feeder Voltage</th>
                                            <th>Feeder Name</th>
                                            <th>Substation</th>
                                            <th>File Name</th> {/* New Column */}
                                            <th>DOC Date</th> {/* New Column */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="17" className="text-center">
                                                    Loading...
                                                </td>
                                            </tr>
                                        ) : (
                                            turbine.map((item, index) => {
                                                let pdfFiles = [];
                                                if (Array.isArray(item.pdf_files)) {
                                                    pdfFiles = item.pdf_files;
                                                } else if (typeof item.pdf_files === "object") {
                                                    pdfFiles = [item.pdf_files]; // Wrap it in an array if it's a single object
                                                }

                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.wtg_no}</td>
                                                        <td>{item.customer_name}</td>
                                                        <td>{item.loc_no}</td>
                                                        <td>{item.htsc_no}</td>
                                                        <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                                                        <td>{item.site_name}</td>
                                                        <td>{item.location_name}</td>
                                                        <td>{item.model_type}</td>
                                                        <td>{item.capacity}</td>
                                                        <td>{item.tower_ht}</td>
                                                        <td>{item.contract_code}</td>
                                                        <td>{item.feeder_voltage}</td>
                                                        <td>{item.feed_name}</td>
                                                        <td>{item.sub_station}</td>
                                                        <td>
                                                            {pdfFiles.length > 0 ? (
                                                                pdfFiles.map((file, fileIndex) => (
                                                                    <a
                                                                        key={fileIndex}
                                                                        href={getPdfFileUrl(file.fileName, file.pdf_id)}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ display: "block" }}
                                                                    >
                                                                        {file.fileName}
                                                                    </a>
                                                                ))
                                                            ) : (
                                                                'No File'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {pdfFiles.length > 0
                                                                ? pdfFiles.map(file => moment(file.pdf_date).format("DD-MM-YYYY")).join(', ')
                                                                : 'No Date'}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>

                                </Table>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    );
};

export default CutomerTurbine;
