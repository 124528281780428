import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Table, ProgressBar, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../Components/Header';
import { Navigate } from 'react-router-dom';
import { Calender, DropDownUI } from '../../Components/Forms'
import { FaFan } from "react-icons/fa";
import moment from 'moment';
import fan from '../../assest/images/fan.png'
import tower from '../../assest/images/tower.png'
import './Dashboard.css';



const DashBoard = () => {
  const [loading, setLoading] = useState(true);
  const [dgrdaily, setDgrdaily] = useState([]);
  const [dgrdailydate, setDgrdailydate] = useState([]);
  console.log("dgrdailydate",dgrdailydate)
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date
  const [dgryearly, setDgryearly] = useState([]);
  const [dgrmonthly, setDgrmonthly] = useState([]);
  const [dgrmon, setDgrmon] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  // Fetch data for the selected date
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission behavior if event exists
    }
  
    setLoading(true); // Indicate that loading has started
  
    try {
      const customerId = localStorage.getItem("customerId");
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD'); // Format the selected date
  
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customerapi/dashboard/daily.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId,
            filter_date: formattedDate // Pass the selected date to the API
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const responseData = await response.json();
      setLoading(false); // Stop loading
  
      if (responseData.status === 200) {
        setDgrdaily(responseData.data);
        setDgrdailydate(responseData); // Update state with the fetched data
      } else {
        throw new Error(responseData.msg); // Handle non-successful status codes
      }
    } catch (error) {
      setLoading(false); // Ensure loading is stopped in case of an error
      console.error("Error fetching data:", error.message);
    }
  };
  
  useEffect(() => {
    handleSubmit(); // Call handleSubmit without an event object
  }, []);
  

  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);

  console.log('print', dgrdailydate)


  // Handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update selectedDate when date changes
  };

  const formatDate = (date) => moment(date).format('DD-MM-YYYY');
  const formatNumber = (number) => number.toLocaleString();


  useEffect(() => {
    const checkSession = async () => {
      const session = localStorage.getItem('session');
      if (!session) setIsLoggedIn(false);
    };
    checkSession();
  }, []);

  if (!isLoggedIn) return <Navigate to="/login" replace />;

  // const totalProduction = dgrdaily.reduce((acc, data) => acc + (data.total_production || 0), 0);



  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }

  // const totalProductionMonth = dgrmonthly.reduce((acc, data) => acc + (data.total_production || 0), 0);

  const customSort = (a, b) => {
    const isNumeric = (value) => /^[0-9]+$/.test(value);
  
    const aIsNumeric = isNumeric(a.wtg_no);
    const bIsNumeric = isNumeric(b.wtg_no);
  
    if (aIsNumeric && bIsNumeric) {
      // Both are numeric, sort numerically
      return parseInt(a.wtg_no, 10) - parseInt(b.wtg_no, 10);
    } else if (aIsNumeric) {
      // `a` is numeric, `b` is alphanumeric
      return -1;
    } else if (bIsNumeric) {
      // `b` is numeric, `a` is alphanumeric
      return 1;
    } else {
      // Both are alphanumeric, sort alphabetically
      return a.wtg_no.localeCompare(b.wtg_no);
    }
  };
  
  
  // Apply the sorting before rendering
  const sortedData = dgrdailydate.monthly?.slice().sort(customSort);
  const customSortDaily = (a, b) => {
    const isNumeric = (value) => /^[0-9]+$/.test(value);
  
    if (isNumeric(a.wtg_no) && isNumeric(b.wtg_no)) {
      // Both are numeric, sort numerically
      return parseInt(a.wtg_no, 10) - parseInt(b.wtg_no, 10);
    } else if (isNumeric(a.wtg_no)) {
      // `a` is numeric, `b` is alphanumeric
      return -1;
    } else if (isNumeric(b.wtg_no)) {
      // `b` is numeric, `a` is alphanumeric
      return 1;
    } else {
      // Both are alphanumeric, maintain the original order
      return 0;
    }
  };
  
  // Apply the sorting before rendering
  const sortedDailyData = dgrdailydate.daily_data?.slice().sort(customSortDaily);
  return (
    <>
      <div id="main">
        <Container fluid className='main my-3'>
          <Row className='d-flex justify-content-center'>
            <Col lg="4" md="8" sm="12" xs="12" className="py-3 d-flex justify-content-center">
              <form onSubmit={handleSubmit} className="w-100 d-flex">
                <div className="w-100 m-1">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)} // Ensure you have a way to update selectedDate
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                </div>
                <div className="w-100">
                  <span className="mx-2">
                    <Button type="submit" className='btn-downn'>Submit</Button>
                  </span>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="mainn">
        <Header />
        <div className="bg-white my-0 rounded">
          <Container fluid>
            <Row>
              <Col lg='4' md='6' xs='12' className='py-3'>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4 responsive-font'>
                      {dgrdailydate?.most_recent_date && formatDate(dgrdailydate.most_recent_date)}
                    </span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    <div className='position-relative'>
                      <img src={fan} alt="Rotating Icon" className='rotate position-absolute' />
                      <img src={tower} alt="Tower Icon" className='position-absolute tower' />
                    </div>
                    <div className='m-4'>
                      <span className='responsive-font'>Total Production :{formatNumber( dgrdailydate.daily_production || 0)}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6' xs='12' className='py-3'>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4  responsive-font'>{dgrdailydate.month}</span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    <div className='position-relative'>
                      <img src={fan} alt="Rotating Icon" className='rotate position-absolute' />
                      <img src={tower} alt="Tower Icon" className='position-absolute tower' />
                    </div>
                    <div className='m-4'>
                      <span className=' responsive-font' >Total Production : {formatNumber(dgrdailydate.monthly_production || 0)}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6' xs='12' className='py-3'>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4 responsive-font'>{dgrdailydate.current_year}</span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    {/* <div className=''>
                      <span className='spin '><FaFan /></span>
                    </div> */}
                    <div className='position-relative'>
                      <img src={fan} alt="Rotating Icon" className='rotate position-absolute' />
                      <img src={tower} alt="Tower Icon" className='position-absolute tower' />
                    </div>
                    <div className='m-4'>
                      <span className=' responsive-font'>Total Production : {formatNumber(dgrdailydate.yearly_production || 0)}</span>
                    </div>
                  </div>
                </div>
              </Col>
              {/* Other columns can remain the same */}
            </Row>
          </Container>
          <div className='m-3'>
            <Row>
              {/* <Col lg={3} className='mb-5'>
                <div className='h5 text-center mb-2'>{dgrdailydate?.most_recent_date && formatDate(dgrdailydate.most_recent_date)}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>

                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdailydate.daily_data?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{data.total_production === 0 ? '-' : formatNumber(data.total_production || 0)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col> */}
              {/* <Col lg={3}>
                <div className='h5 text-center mb-2'>{dgrdailydate.month}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdailydate.monthly?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{formatNumber(data.total_production || 0)}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col> */}
                  <Col lg={3} className='mb-5'>
                <div className='h5 text-center mb-2'>{dgrdailydate?.most_recent_date && formatDate(dgrdailydate.most_recent_date)}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>

                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                    {sortedDailyData?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{data.total_production === 0 ? '-' : formatNumber(data.total_production || 0)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
                 <Col lg={3}>
                <div className='h5 text-center mb-2'>{dgrdailydate.month}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                    {sortedData?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{formatNumber(data.total_production || 0)}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={3}>
                <div className='h5 text-center mb-2'>Machine Availability </div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Machine Availability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdailydate.daily_data?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>
                            {data.machine_availability ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', marginRight: '10px' }}>
                                  <ProgressBar
                                    now={parseFloat(data.machine_availability)}
                                    label={`${data.machine_availability}%`}
                                    variant="success"
                                  />
                                </div>
                              </div>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={3}>
                <div className='h5 text-center mb-2'>Grid Availability </div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Grid Availability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdailydate.daily_data?.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>

                          <td>
                            {data.grid_availability ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '100%', marginRight: '10px' }}>
                                  <ProgressBar
                                    now={parseFloat(data.grid_availability)}
                                    label={`${data.grid_availability}%`}
                                    variant="success"
                                  />
                                </div>
                              </div>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
          {/* Other sections can remain the same */}
        </div>
      </div>
    </>
  );
};

export default DashBoard;
