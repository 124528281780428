import React, { useState, useEffect, forwardRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Dropdown, Modal, Form, Button } from 'react-bootstrap';
import { MdOutlinePerson, MdOutlineDashboard, MdOutlineExitToApp } from "react-icons/md";
import { ImExit } from "react-icons/im";
import { FaLock } from "react-icons/fa";
import '../Components/Header.css';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';  // For npm installation
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { DropDownUI, TextForm } from "../../src/Components/Forms";
const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  useEffect(() => {
    const session = localStorage.getItem("session");
    const storedCustomerName = localStorage.getItem("customerName");
    if (session) {
      setLoggedIn(true);
      setCustomerName(storedCustomerName);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("customerName");
    window.location.replace("/login");
  };

  const handlePasswordChange = async () => {
    const customerId = localStorage.getItem("customerId");
    const payload = {
      id: customerId,
      password: currentPassword,
      new_password: newPassword,
    };

    try {
      const response = await fetch('https://api.srivarugreenenergy.com/forgotpassword/forgot.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Password Changed Successfully',

        });
        setCurrentPassword('');
        setNewPassword('');
        setShowModal(false);
        handleLogout();
      } else if (response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Incorrect Current Password',

        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          text: `Server Error, Please Try Again Later`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Server Error, Please Try Again Later',
      });
      console.error(error);
    }
  };


  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </span>
  ));

  return (
    <>
      <Navbar className='navfix navbg header fixed-top' expand="lg">
        <Container fluid className='px-5'>
          <div className='user-logo'>
            <img src={require('../assest/images/srivarulogo.png')} className='img-fluid logo' alt="Logo" />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <NavLink to="/console/dashboard" className="nav-link">
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span className="list-text">Dashboard</span>
              </NavLink>
              <NavLink to="/console/dailygen" className="nav-link">
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span className="list-text">Daily Generation</span>
              </NavLink>
              <NavLink to="/console/dgr" className="nav-link">
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span className="list-text">Dgr Report</span>
              </NavLink>
              <NavLink to="/console/turbine" className="nav-link">
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span className="list-text">Turbine</span>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <div className='ms-auto user-id'>
            {loggedIn && (
              <div className='d-flex align-items-center'>
                <span className="customer-name mt-2">{customerName}</span>
                <span className='ms-2'>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                      <MdOutlinePerson style={{ cursor: 'pointer', fontSize: '24px' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='drop-lefts'>

                      <Dropdown.Item onClick={() => setShowModal(true)} className="d-flex align-items-center">
                        <FaLock className="me-2" />
                        Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout} className="d-flex align-items-center">
                        <ImExit className="me-2" />
                        LogOut
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            )}
          </div>
        </Container>
      </Navbar>

      {/* Modal for Password Change */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" value={customerName} readOnly />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
            </Form.Group> */}
            <div className="py-3">
              <TextForm
                labelname={'Current Password'}
                placeholder={"Current Password"}
                type={showPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                suffix_icon={
                  showPassword ? (
                    <VscEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <VscEyeClosed onClick={() => setShowPassword(true)} />
                  )
                }
              />
            </div>
            {/* <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </Form.Group> */}
            <div className="py-3">
              <TextForm
                labelname={'New Password'}
                placeholder={"New Password"}
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                suffix_icon={
                  showPassword ? (
                    <VscEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <VscEyeClosed onClick={() => setShowPassword(true)} />
                  )
                }
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePasswordChange}>
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
